import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Header,
  Icon,
  Message,
  Modal,
} from "semantic-ui-react";
import ProductInputs from "./ProductInputs";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../../api/index";
import { isLoading } from "../../../utils/isLoading";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { number, object, string } from "yup";
import { Input, Select } from "../../form";
import {
  defaultProductValues,
  productValidationSchema,
} from "./schemas/ProductSchema";

const VEHICLE_MODELS = {
  Tesla: ["Model 3", "Model Y"],
  Mercedes: ["EQC"],
};

const requiredString = string().required();
const positiveRequiredNumber = number().positive().required();

const validationSchema = object({
  ...productValidationSchema(VEHICLE_MODELS),
  etagNumber: requiredString.label("Etag number"),
  teslaApiId: requiredString.label("API ID"),
  accessTokenId: requiredString.label("Access token ID"),
  weekendRate: positiveRequiredNumber
    .label("Weekend rate")
    .typeError("Weekend rate must be a number"),
  overnightRate: positiveRequiredNumber
    .label("Overnight rate")
    .typeError("Overnight rate must be a number"),
  dailyCap: positiveRequiredNumber
    .label("Daily cap")
    .typeError("Daily cap must be a number"),
}).required();

export default function CreateVehicleModal() {
  const { getAccessTokenSilently } = useAuth0();
  const [accessTokens, setAccessTokens] = useState({
    state: "loading",
    value: [],
  });
  const [open, setOpen] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      ...defaultProductValues,
      etagNumber: "",
      teslaApiId: "",
      accessTokenId: null,
      hireRatePerHour: "",
      weekendRate: "",
      overnightRate: "",
      dailyCap: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = formMethods;

  const fetchData = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const fetchedAccessTokens = await api.getAccessTokens(accessToken);

    setAccessTokens({ state: "success", value: fetchedAccessTokens });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = useCallback(
    async (data) => {
      const accessToken = await getAccessTokenSilently();

      try {
        await api.createVehicle(accessToken, data);
      } catch (e) {
        setError("root.serverError", {
          type: "500",
        });
        console.log(e);
      }
    },
    [getAccessTokenSilently, setError]
  );

  return (
    <FormProvider {...formMethods} handleSubmit={handleSubmit(onSubmit)}>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Dropdown.Item text="Vehicle" />}
      >
        <Modal.Header>Add a new vehicle</Modal.Header>

        <Modal.Content>
          <Form
            success={isSubmitSuccessful}
            loading={isSubmitting}
            error={errors.root}
          >
            <ProductInputs productModels={VEHICLE_MODELS} />

            <Input name="etagNumber" label="Etag Number" />

            <Header>APIs</Header>

            <Form.Group widths="equal">
              <Input name="teslaApiId" label="API ID" />

              <Select
                name="accessTokenId"
                label="Access token"
                options={accessTokens.value.map((accessToken) => ({
                  key: accessToken.id,
                  text: accessToken.name,
                  value: accessToken.id,
                  description: accessToken.provider,
                }))}
                loading={isLoading(accessTokens)}
              />
            </Form.Group>

            <Header>Rates</Header>

            <Form.Group widths="equal">
              <Input
                name="hireRatePerHour"
                label="Hire rate per hour"
                placeholder="13.50"
                type="number"
                icon="dollar"
                iconPosition="left"
              />

              <Input
                name="weekendRate"
                label="Weekend rate"
                placeholder="13.50"
                type="number"
                icon="dollar"
                iconPosition="left"
              />

              <Input
                name="overnightRate"
                label="Overnight rate"
                placeholder="13.50"
                type="number"
                icon="dollar"
                iconPosition="left"
              />

              <Input
                name="dailyCap"
                label="Daily cap"
                placeholder={50}
                type="number"
                icon="dollar"
                iconPosition="left"
              />
            </Form.Group>

            <Divider hidden />

            <Message success icon>
              <Icon name="check circle" />
              <Message.Content>
                <Message.Header>Success</Message.Header>
                Successfully added vehicle
              </Message.Content>
            </Message>

            <Message error icon>
              <Icon name="times circle" />
              <Message.Content>
                <Message.Header>Internal server error</Message.Header>
                Failed to add charger
              </Message.Content>
            </Message>
          </Form>
        </Modal.Content>

        <Modal.Actions>
          <Button basic onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            content="Add vehicle"
            labelPosition="right"
            icon="add"
            positive
          />
        </Modal.Actions>
      </Modal>
    </FormProvider>
  );
}
