import React from "react";
import { FormCheckbox } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI checkbox component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 *  label: string
 * }} props
 */
export function Checkbox({ name, required, label, ...props }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? false,
      }}
      render={({
        field: { value, onChange, ref, ...field },
        fieldState: { error },
      }) => (
        <FormCheckbox
          {...props}
          {...field}
          label={label}
          // NOTE (Matt): Semantic UI/HTML checkbox expects an empty string for true and undefined for false
          value={value ? "" : undefined}
          onChange={(e, data) => {
            onChange(data.checked);

            if (props.onChange) {
              props.onChange(e, data);
            }
          }}
          error={
            error && {
              content: error.message,
              pointing: "left",
            }
          }
        />
      )}
    />
  );
}
