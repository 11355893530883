import React from "react";
import { FormSelect } from "semantic-ui-react";
import { Controller, useFormContext } from "react-hook-form";

/**
 * Enables the Semantic UI select component to be used with React Hook Form
 * @param {{
 *  name: string
 *  required: boolean
 *  options: {
 *    key: *,
 *    text: string,
 *    value: string,
 *    description?: string
 *  }
 *  label: string
 *  placeholder: string
 * }} props
 */
export function Select({
  name,
  required,
  options,
  label,
  placeholder,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ?? true,
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <FormSelect
          {...props}
          {...field}
          search
          label={label}
          options={options ? options : []}
          placeholder={placeholder ?? label}
          required={required ?? true}
          onChange={(e, data) => {
            field.onChange(data.value);

            if (props.onChange) {
              props.onChange(e, data);
            }
          }}
          error={
            error && {
              content: error.message,
              pointing: "above",
            }
          }
          noResultsMessage="No results"
        />
      )}
    />
  );
}
